import React, {useEffect, useState} from "react";
import ViewToggle from "./ViewToggle";
import views from "../constants/views";
import {useDispatch, useSelector} from "react-redux";
import {fetchTasks} from "../actions/tasks";
import NewTaskModal from "./NewTaskModal";
import EditTaskModal from "./EditTaskModal";
import FutureTaskList from "./FutureTaskList";
import ClosedTaskList from "./ClosedTaskList";
import OrderableTaskList from "./OrderableTaskList";
import TaskListProvider from "./TaskListProvider";
import NewTaskButton from "./NewTaskButton";
import DateOptionsModal from "./DateOptionsModal";
import CustomDateModal from "./CustomDateModal";
import RefreshTasksButton from "./RefreshTasksButton";

function Tasks() {
    const view = useSelector(state => state.view);
    const isLoading = useSelector(state => state.loading.loading);
    const [title, setTitle] = useState([]);
    const [taskList, setTaskList] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTasks());

        switch (view) {
            case views.future:
                setTitle('Scheduled tasks');
                setTaskList(<FutureTaskList />);
                break;
            case views.closed:
                setTitle('Tasks done');
                setTaskList(<ClosedTaskList />);
                break;
            default:
                setTitle("Today's tasks");
                setTaskList(<OrderableTaskList />);
                break;
        }
    }, [view, dispatch]);

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{title}</h1>
                <div className="d-flex">
                    <RefreshTasksButton />
                    <ViewToggle />
                </div>
                <NewTaskModal />
                <EditTaskModal />
                <DateOptionsModal />
                <CustomDateModal />
            </div>
            {taskList !== '' && ! isLoading && (
                <TaskListProvider>
                    {taskList}
                </TaskListProvider>
            )}

            <NewTaskButton />
        </>
    )
}

export default Tasks;