import views from "../constants/views";
import TaskHelper from "../libs/TaskHelper";
import arrayMove from "array-move";
import {startLoading, stopLoading} from "../../admin";

const TASK_SET = 'TASK_SET';

const createTask = task => dispatch => {
    TaskHelper
        .create(task.subject, task.description, task.task_repeat, task.sub_tasks, task.files.map(file => file.id), task.date)
        .then(() => dispatch(fetchTasks()));
};

const updateTask = task => dispatch => {
    TaskHelper
        .update(task.id, task.subject, task.description, task.status, task.task_repeat, task.sub_tasks, task.files.map(file => file.id), task.date)
        .then(() => dispatch(fetchTasks()))
};

const closeTask = task => dispatch => TaskHelper.close(task.id).then(() => dispatch(fetchTasks()));
const openTask = task => dispatch => TaskHelper.open(task.id).then(() => dispatch(fetchTasks()));

const fetchTasks = () => (dispatch, getState) => {
    const view = getState().view;

    dispatch(startLoading());

    switch (view) {
        case views.future:
            TaskHelper.getFuture().then((data) => {
                dispatch(stopLoading());
                return dispatch(setTasks(data));
            });
            break;
        case views.closed:
            TaskHelper.getClosed().then((data) => {
                dispatch(stopLoading());
                return dispatch(setTasks(data));
            });
            break;
        default:
            TaskHelper.getToday().then((data) => {
                dispatch(stopLoading());
                return dispatch(setTasks(data));
            });
            break;
    }
};

const setTasks = data => {
    return {
        type: TASK_SET,
        tasks: data
    }
};

const moveTasks = (oldIndex, newIndex) => (dispatch, getState) => {
    const tasks = getState().tasks;
    const newTasks = arrayMove(tasks, oldIndex, newIndex);

    const newPositions = newTasks.reduce((dict, task, index) => {
        dict[task.id] = index;
        return dict
    }, {});

    TaskHelper.changePositions(newPositions);

    dispatch(setTasks(newTasks));
};

export {fetchTasks, moveTasks, createTask, closeTask, openTask, updateTask, TASK_SET};