import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Button, Form, Row, Col} from "react-bootstrap";
import {editTaskClose, setSubject, setDescription} from "../actions/forms";
import {updateTask} from "../actions/tasks";
import DateField from "./DateField";
import SubTaskField from "./SubTaskField";
import RepeatField from "./RepeatField";
import FileField from "./FileField";

function EditTaskModal() {
    const show = useSelector((state) => state.task.forms.editTaskOpen);
    const task = useSelector((state) => state.task.forms);
    const dispatch = useDispatch();

    const close = () => {
        dispatch(editTaskClose())
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateTask({
            id: task.id,
            subject: task.subject,
            description: task.description,
            date: task.date,
            task_repeat: task.task_repeat,
            sub_tasks: task.sub_tasks,
            files: task.files
        }));

        close();
    };

    return (
        <Modal show={show} onHide={close} size="xl">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form.Group controlId="subject">
                        <Form.Control required type="text" placeholder="The subject of your task"
                                      value={task.subject || ''}
                                      onChange={(e) => dispatch(setSubject(e.target.value))}/>
                    </Form.Group>
                    <Row className="mt-3">
                        <Col>
                            <SubTaskField />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <FileField />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Group controlId="description">
                                <Form.Control as="textarea" placeholder="Description"
                                              value={task.description || ''}
                                              onChange={(e) => dispatch(setDescription(e.target.value))}/>
                            </Form.Group>
                            <DateField />
                            <RepeatField />
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={close} variant="secondary">Close</Button>
                    <Button variant="primary" type="submit">Update</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default EditTaskModal;