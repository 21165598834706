import React from "react";
import '../style/date_field.scss'
import dateFormat from "dateformat";
import DateHelper from "../libs/DateHelper";
import {showDateOptions} from "../actions/dateSelection";
import {useDispatch, useSelector} from "react-redux";

function DateField() {
    const dispatch = useDispatch();
    const value = useSelector(store => store.task.forms.date);

    const getDateObject = (date) => {
        if (date === undefined || date === null)
            return null;

        return new Date(date);
    };

    const DateView = ({date}) => {
        const dateObject = getDateObject(date);
        return (
            <button onClick={() => dispatch(showDateOptions())} type="button" className="btn btn-default">
                <i className="fa fa-clock"></i> {dateObject === null ? 'Unspecified' : dateFormat(getDateObject(date), DateHelper.getDateFormatString())}
            </button>
        );
    };

    return <DateView date={value}/>
}

export default DateField;