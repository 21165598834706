import React, {useEffect, useState} from "react";
import Auth from "../../../auth/libs/Auth";
import {Link} from "react-router-dom";
import md5 from 'md5'
import {useDispatch} from "react-redux";
import {search, searchBlur, searchFocus} from "../../actions/search";

function Navigation() {
    const defaultAvatarUrl = '';

    const [fullName, setFullName] = useState('');
    const [, setEmail] = useState('');
    const [avatarUrl, setAvatarUrl] = useState(defaultAvatarUrl);

    const dispatch = useDispatch();

    const fetchUserData = () => {
        Auth.getUserData()
            .then((data) => {
                setFullName(data.name);
                setEmail(data.email);
                const mailHash = md5(data.email);
                setAvatarUrl('https://www.gravatar.com/avatar/' + mailHash  + '?d=mp&s=60');
            });
    };

    const logout = () => {
        Auth.logout();
        window.location.reload();
    };

    const changeSearch = e => {
        dispatch(search(e.target.value));
    };

    const blurSearch = e => {
        dispatch(searchBlur());
    };

    const focusSearch = e => {
        dispatch(searchFocus());
    };

    useEffect(() => fetchUserData());

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary" onClick={logout}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>

            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
            </button>

            <form
                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                           placeholder="Search for..."
                           aria-label="Search" aria-describedby="basic-addon2" onChange={changeSearch}
                           onFocus={focusSearch} onBlur={blurSearch}/>
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <button className="nav-link btn btn-link dropdown-toggle" id="searchDropdown"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                         aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small"
                                       placeholder="Search for..."
                                       aria-label="Search" aria-describedby="basic-addon2"
                                       onChange={changeSearch} onFocus={focusSearch} onBlur={blurSearch}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                {
                    /*
                <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw"></i>
                        <span className="badge badge-danger badge-counter">3+</span>
                    </a>
                    <div
                        className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="alertsDropdown">
                        <h6 className="dropdown-header">
                            Alerts Center
                        </h6>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="mr-3">
                                <div className="icon-circle bg-primary">
                                    <i className="fas fa-file-alt text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 12, 2019</div>
                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                            </div>
                        </a>
                    </div>
                </li>
                     */
                }

                <div className="topbar-divider d-none d-sm-block"></div>

                <li className="nav-item dropdown no-arrow">
                    <button className="nav-link btn btn-link dropdown-toggle" id="userDropdown"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span
                                            className="mr-2 d-none d-lg-inline text-gray-600 small">{fullName}</span>
                        <img className="img-profile rounded-circle" src={avatarUrl} alt="avatar" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                         aria-labelledby="userDropdown">
                        <Link className="dropdown-item" to="/profile">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </Link>
                        {/*
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Settings
                        </a>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </a>
                        */}
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item" data-toggle="modal"
                           data-target="#logoutModal">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </button>
                    </div>
                </li>

            </ul>

        </nav>
    );
}

export default Navigation;