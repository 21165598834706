import React, {useState} from "react";
import {Container, Alert} from "react-bootstrap";
import Auth from "../libs/Auth";
import RegistrationForm from "./RegistrationForm";


function Register() {
    document.body.classList.add('bg-gradient-primary');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [registered, setRegistered] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        Auth.register(firstName + ' ' + lastName, email, password, repeatPassword)
            .then(() => {
                setRegistered(true);
            })
            .catch((errors) => {
                setErrors(Object.values(errors));
            });
    };

    const RegisteredNotice = () => (
        <Alert variant="success" className="mt-5">
            Registration successful, please check your e-mail for an activation link
        </Alert>
    );

    const registrationFormProps = {
        setFirstName, setLastName, setEmail, setPassword, setRepeatPassword, errors, handleSubmit
    };

    return (
        <Container>
            {registered ? <RegisteredNotice /> : <RegistrationForm {...registrationFormProps} /> }

        </Container>
    );
}

export default Register;