const taskFormActions = {
    TASK_SET_CURRENT: 'TASK_SET_CURRENT',
    TASK_NEW_OPEN: 'TASK_NEW_OPEN',
    TASK_NEW_CLOSE: 'TASK_NEW_CLOSE',
    TASK_EDIT_OPEN: 'TASK_EDIT_OPEN',
    TASK_EDIT_CLOSE: 'TASK_EDIT_CLOSE',
    TASK_EDIT_FORM_SET_SUBJECT: 'TASK_EDIT_FORM_SET_SUBJECT',
    TASK_EDIT_FORM_SET_DATE: 'TASK_EDIT_FORM_SET_DATE',
    TASK_EDIT_FORM_SET_DESCRIPTION: 'TASK_EDIT_FORM_SET_DESCRIPTION',
    TASK_REPEAT_SET: 'TASK_REPEAT_SET',
    SUB_TASKS_SET: 'SUB_TASKS_SET',
    SUB_TASK_ADD: 'SUB_TASK_ADD',
    SUB_TASK_OPEN: 'SUB_TASK_OPEN',
    SUB_TASK_CLOSE: 'SUB_TASK_CLOSE',
    SUB_TASK_DELETE: 'SUB_TASK_DELETE',
    SUB_TASK_SET_SUBJECT: 'SUB_TASK_SET_SUBJECT',
    FILE_ADD: 'FILE_ADD',
    FILE_DELETE: 'FILE_DELETE'
};

const newTaskOpen = () => {
    return {
        type: taskFormActions.TASK_NEW_OPEN
    };
};

const newTaskClose = () => {
    return {
        type: taskFormActions.TASK_NEW_CLOSE
    };
};

const setCurrentTask = (task) => {
    return {
        type: taskFormActions.TASK_SET_CURRENT,
        task
    };
};

const editTaskOpen = task => dispatch => {
    return dispatch({
        type: taskFormActions.TASK_EDIT_OPEN,
        task
    });
};

const editTaskClose = (task) => {
    return {
        type: taskFormActions.TASK_EDIT_CLOSE,
        task
    };
};

const setSubject = (subject) => {
    return {
        type: taskFormActions.TASK_EDIT_FORM_SET_SUBJECT,
        subject
    }
};

const setDescription = (description) => {
    return {
        type: taskFormActions.TASK_EDIT_FORM_SET_DESCRIPTION,
        description
    }
};

const setDate = date => {
    return {
        type: taskFormActions.TASK_EDIT_FORM_SET_DATE,
        date
    }
};

const setTaskRepeat = period => {
    return {
        type: taskFormActions.TASK_REPEAT_SET,
        period
    }
}

const setCurrentSubTasks = subTasks => {
    return {
        type: taskFormActions.SUB_TASKS_SET,
        subTasks
    }
}

const addUnsavedSubTask = hash => {
    return {
        type: taskFormActions.SUB_TASK_ADD,
        hash
    }
}

const openUnsavedSubTask = subTask => {
    return {
        type: taskFormActions.SUB_TASK_OPEN,
        subTask
    }
}

const closeUnsavedSubTask = subTask => {
    return {
        type: taskFormActions.SUB_TASK_CLOSE,
        subTask
    }
}

const deleteUnsavedSubTask = subTask => {
    return {
        type: taskFormActions.SUB_TASK_DELETE,
        subTask
    }
}

const editSubTaskSubject = (subTask, subject) => {
    return {
        type: taskFormActions.SUB_TASK_SET_SUBJECT,
        subject,
        subTask
    }
}

const addFile = file => ({
    type: taskFormActions.FILE_ADD,
    file
});

const deleteFile = fileId => ({
    type: taskFormActions.FILE_DELETE,
    fileId
});

export {
    newTaskOpen,
    newTaskClose,
    editTaskClose,
    editTaskOpen,
    setSubject,
    setDescription,
    setDate,
    setCurrentTask,
    setTaskRepeat,
    setCurrentSubTasks,
    addUnsavedSubTask,
    editSubTaskSubject,
    openUnsavedSubTask,
    closeUnsavedSubTask,
    deleteUnsavedSubTask,
    addFile,
    deleteFile,
    taskFormActions
};