import ErrorNotice from "./ErrorNotice";
import {Link} from "react-router-dom";
import React from "react";

function RegistrationForm({setFirstName, setLastName, setEmail, setPassword, setRepeatPassword, errors, handleSubmit}) {
    return (
        <>
            {errors && <ErrorNotice errors={errors}/>}
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                        <div className="col-lg-7">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                                </div>
                                <form className="user" onSubmit={handleSubmit}>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" className="form-control form-control-user"
                                                   onChange={(e) => setFirstName(e.target.value)}
                                                   placeholder="First Name"
                                                   required/>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="text" className="form-control form-control-user"
                                                   onChange={(e) => setLastName(e.target.value)}
                                                   placeholder="Last Name"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control form-control-user"
                                               onChange={(e) => setEmail(e.target.value)}
                                               required
                                               placeholder="Email Address"/>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="password" className="form-control form-control-user"
                                                   onChange={(e) => setPassword(e.target.value)}
                                                   minlength="8"
                                                   placeholder="Password"/>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="password" className="form-control form-control-user"
                                                   onChange={(e) => setRepeatPassword(e.target.value)}
                                                   placeholder="Repeat Password"/>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                        Register Account
                                    </button>
                                </form>
                                <hr/>
                                <div className="text-center">
                                    <Link className="small" to="/forgot-password">Forgot Password?</Link>
                                </div>
                                <div className="text-center">
                                    <Link className="small" to="/">Already have an account? Login!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RegistrationForm;