import React from "react";
import '../style/index.scss'
import TaskItem from "./TaskItem";
import views from "../constants/views";
import {useSelector} from "react-redux";

function ClosedTaskList({tasks}) {
    const searchString = useSelector(store => store.search.search);

    return (
        <ul className="task-list align-middle">
            {Object.keys(tasks).length === 0 &&
            (
                searchString === ''
                    ? <span>What? You haven't done anything yet today... Get to work!</span>
                    : <span><i className="fa fa-search"></i> Nothing found for "{searchString}"</span>
            )}
            {tasks.map((task) => {
                return (
                    <TaskItem key={`item-${task.id}`}  view={views.closed} className="task-item" task={task}/>
                )
            })}
        </ul>
    );
}

export default ClosedTaskList;