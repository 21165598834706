import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import Auth from "../libs/Auth";
import ErrorNotice from "./ErrorNotice";

function ForgotPassword({history}) {
    document.body.classList.add('bg-gradient-primary');

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        Auth.forgotPassword(email)
            .then(() => {
                alert('A reset link has been sent to your e-mail.');
                history.push('/');
            })
            .catch(() => setErrors(['An error occured when sending you a password link. Please try again later']))
        ;
    };

    return (
        <Container>
            {errors && <ErrorNotice errors={errors} />}
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                        <div className="col-lg-6">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Forgot your password?</h1>
                                    <p className="mb-4">We get it, stuff happens. Just enter your email address below
                                        and we'll send you a link to reset your password!</p>
                                </div>
                                <form className="user" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input type="email" className="form-control form-control-user"
                                               aria-describedby="emailHelp"
                                               onChange={(e) => setEmail(e.target.value)}
                                               placeholder="Enter Email Address..."
                                               required />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                        Reset Password
                                    </button>
                                </form>
                                <hr/>
                                <div className="text-center">
                                    <Link className="small" to="/register">Create an Account!</Link>
                                </div>
                                <div className="text-center">
                                    <Link className="small" to="/">Already have an account? Login!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ForgotPassword;