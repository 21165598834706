import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {animateScroll} from "react-scroll";

function Footer() {
    const scrollToTop = (event) => {
        event.preventDefault();
        animateScroll.scrollToTop();
    };

    const handleScroll = (e) => {
        setScroll(window.scrollY);
    };

    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    });


    return (
        <footer className="sticky-footer bg-white">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright © <a target="_blank" rel="noopener noreferrer" href="https://thewiseminds.nl">Wise Minds Academy</a></span>
                </div>
            </div>
            <a className={scroll > 100 ? "scroll-to-top rounded visible" : "scroll-to-top rounded hidden" } onClick={(e) => scrollToTop(e)} href="#page-top">
                <FontAwesomeIcon icon="angle-up"/>
            </a>
        </footer>
    );
}

export default Footer;