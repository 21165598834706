import {TASK_SET} from "../actions/tasks";

const tasks = (state, action) => {
    switch (action.type) {
        case TASK_SET:
            return action.tasks;
        default:
            return state || [];
    }
};

export default tasks;