import { combineReducers } from 'redux'
import {taskReducers as task, tasks, view} from './tasks'
import {loading, search} from "./admin";

const taskApp = combineReducers({
    tasks,
    view,
    task,
    search,
    loading
});

export default taskApp;