import React from "react";
import {Button} from "react-bootstrap";
import {fetchTasks} from "../actions/tasks";
import {useDispatch} from "react-redux";

const RefreshTasksButton = () => {
    const dispatch = useDispatch()

    return <Button onClick={() => dispatch(fetchTasks())} className="mr-2" variant="primary">
            <i className="fa fa-sync-alt"></i>
        </Button>
}

export default RefreshTasksButton;