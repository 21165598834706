import React from "react";
import {Login, Activate, Register, ForgotPassword} from "./auth";
import {Tasks} from "./tasks";
import {Admin} from "./admin";
import ResetPassword from "./auth/components/ResetPassword";

const loggedInRoutes = [
    {
        name: 'Todays Tasks',
        path: '/',
        component: (props) => <Admin><Tasks {...props} /></Admin>
    }
];

const guestRoutes = [
    {
        name: 'Login',
        path: '/',
        exact: true,
        component: (props) => <Login {...props} />
    },
    {
        name: 'Verify',
        path: '/verify',
        component: (props) => <Activate {...props} />
    },
    {
        name: 'Register',
        path: '/register',
        component: (props) => <Register {...props} />
    },
    {
        name: "Forgot Password",
        path: '/forgot-password',
        component: (props) => <ForgotPassword {...props} />
    },
    {
        name: "Reset Password",
        path: '/reset-password',
        component: (props) => <ResetPassword {...props} />
    }
];

export {loggedInRoutes, guestRoutes};