const LOADING_START = 'LOADING_START';
const LOADING_END = 'LOADING_END';

const stopLoading = () => ({
    type: LOADING_END
});

const startLoading = () => ({
    type: LOADING_START
});

export {startLoading, stopLoading, LOADING_START, LOADING_END};