import Tasks from "./components/Tasks";
import view from './reducers/view';
import tasks from './reducers/tasks';
import forms from './reducers/forms';
import dateSelection from './reducers/dateSelection';
import {newTaskClose, newTaskOpen} from './actions/forms'

import {combineReducers} from "redux";

const taskReducers = combineReducers({forms, dateSelection});

export {Tasks, taskReducers, tasks, view, newTaskClose, newTaskOpen};