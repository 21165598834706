import DateHelper from "../libs/DateHelper";
import moment from "moment";
import {Col, Modal, Row} from "react-bootstrap";
import React from "react";
import {hideDateSelection, showDateCustom} from "../actions/dateSelection";
import {useDispatch, useSelector} from "react-redux";
import dateSelectionViews from "../constants/dateSelectionViews";
import {setDate} from "../actions/forms";
import dateFormat from "dateformat";
import {updateTask} from "../actions/tasks";

function DateOptionsModal() {
    const dispatch = useDispatch();

    const saveDirectly = useSelector(store => store.task.dateSelection.saveDirectly);
    const show = useSelector(store => store.task.dateSelection.view === dateSelectionViews.options);
    const task = useSelector((state) => state.task.forms);

    const onDateChange = date => {
        const newDate = date === null ? null : dateFormat(date, DateHelper.getDateFormatString());
        dispatch(setDate(newDate));
        if (saveDirectly) {
            dispatch(updateTask(Object.assign({}, task, {date: newDate, status: 'open'})));
        }
    };

    const isMorning = DateHelper.isMorning();
    const evening = DateHelper.getEvening();
    const laterToday = DateHelper.getLaterToday();
    const tomorrow = DateHelper.getTomorrow();
    const tomorrowEvening = DateHelper.getTomorrowEvening();
    const afterTomorrow = DateHelper.getAfterTomorrow();
    const afterTomorrowName = moment.weekdays()[afterTomorrow.getDay()];
    const comingWeekend = DateHelper.getComingWeekend();
    const nextWeek = DateHelper.getNextWeek();


    const close = () => dispatch(hideDateSelection());

    return (
        <Modal show={show} onHide={close}>
            <Modal.Body>
                <Row>
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(laterToday);
                            close();
                        }}><i className="fa fa-coffee"></i> Later Today
                        </button>
                    </Col>
                    {isMorning && (
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                onDateChange(evening);
                                close();
                            }}><i className="fa fa-moon"></i> This Evening
                            </button>
                        </Col>
                    )}
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(tomorrow);
                            close();
                        }}><i className="fa fa-sun"></i> Tomorrow
                        </button>
                    </Col>
                    {!isMorning && (
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                onDateChange(tomorrowEvening);
                                close();
                            }}><i className="fa fa-moon"></i> Tomorrow Evening
                            </button>
                        </Col>
                    )}
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(afterTomorrow);
                            close();
                        }}><i className="fa fa-share"></i> {afterTomorrowName}</button>
                    </Col>
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(comingWeekend);
                            close();
                        }}><i className="fa fa-cocktail"></i> Coming Weekend
                        </button>
                    </Col>
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(nextWeek);
                            close();
                        }}><i className="fa fa-pause"></i> Next Week
                        </button>
                    </Col>
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => {
                            onDateChange(null);
                            close();
                        }}>
                            <i className="fa fa-question"></i> Unspecified
                        </button>
                    </Col>
                    <Col xs="6" md="4">
                        <button className="btn btn-default time-option" onClick={() => dispatch(showDateCustom())}>
                            <i className="fa fa-calendar"></i> Custom
                        </button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default DateOptionsModal;