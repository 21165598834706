import {TOGGLE_VIEW} from "../actions/view";
import views from "../constants/views";

const view = (state = views.today, action) => {
    switch (action.type) {
        case TOGGLE_VIEW:
            return action.view;
        default:
            return state;
    }
};

export default view;