import {LOADING_START, LOADING_END} from "../actions/loading";

const loading = (state = {loading: false}, action) => {
    switch (action.type) {
        case LOADING_START:
            return {loading: true};
        case LOADING_END:
            return {loading: false};
        default:
            return state;
    }
};

export default loading;