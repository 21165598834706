import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addFile, deleteFile} from "../actions/forms";
import Api from "../../libs/Api";

const FileField = () => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const files = useSelector(store => store.task.forms.files || []);
    const uploadRef = useRef();

    const remove = fileId => {
        dispatch(deleteFile(fileId));
    }

    const onUploadComplete = file => {
        dispatch(addFile(file));
    }

    const onChange = e => {
        setUploading(true);
        const uploadingFiles = Array.from(e.target.files);

        let formData = new FormData();
        console.log(uploadingFiles[0])
        formData.append('file', uploadingFiles[0]);
        Api.post('api/file', formData)
            .then(response => {
                if (response.data.status === 'success') {
                    onUploadComplete(response.data.data)
                }
            })
            .finally(() => {
                setUploading(false)
            });
    }

    const add = () => {
        uploadRef.current.click();
    }

    return (
        <div>
            <div className="task-files">
                <input style={{display: "none"}} type='file' ref={uploadRef} onChange={onChange} multiple />
                {files.map((file) => (
                    <div key={file.id + file.path} className="file">
                        <a href={file.url} download={file.name}>{file.name}</a>

                        <button tabIndex="-1" onClick={e => {
                            e.preventDefault();
                            remove(file.id)
                        }} className="btn btn-default delete-button"><i className="fa fa-trash"></i></button>
                    </div>
                ))}
            </div>
            <button onClick={() => add()} type="button" className="btn btn-sm btn-secondary" disabled={uploading}>
                {uploading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-plus"></i>} Add file
            </button>
        </div>
    )
}

export default FileField;