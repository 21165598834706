import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Col, Modal, Row} from "react-bootstrap";
import {setTaskRepeat} from "../actions/forms";

const RepeatField = () => {
    const repeat = useSelector(store => store.task.forms.task_repeat || []);
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    const setPeriod = period => {
        dispatch(setTaskRepeat(period));
    }

    let repeatText = ''
    switch (repeat.period) {
        case "day":
            repeatText = 'Repeat every day'
            break;
        case "week":
            repeatText = 'Repeat every week'
            break;
        case "month":
            repeatText = 'Repeat every month'
            break;
        case "year":
            repeatText = 'Repeat every year'
            break;
        default:
            repeatText = 'No repeat schedule'
    }

    return (
        <div className="repeat">
            <button type="button" className="btn btn-default" onClick={() => setModalOpen(true)}>
                <i className="fa fa-redo"></i> {repeatText}
            </button>
            <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                <Modal.Body>
                    <Row>
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                setPeriod(null)
                                setModalOpen(false)
                            }}><i className="fa fa-times"></i> No Repeat
                            </button>
                        </Col>
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                setPeriod('day')
                                setModalOpen(false)
                            }}><i className="fa fa-sun"></i> Repeat Daily
                            </button>
                        </Col>
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                setPeriod('week')
                                setModalOpen(false)
                            }}><i className="fa fa-calendar-week"></i> Repeat Weekly
                            </button>
                        </Col>
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                setPeriod('month')
                                setModalOpen(false)
                            }}><i className="fa fa-moon"></i> Repeat Monthly
                            </button>
                        </Col>
                        <Col xs="6" md="4">
                            <button className="btn btn-default time-option" onClick={() => {
                                setPeriod('year')
                                setModalOpen(false)
                            }}><i className="fa fa-calendar-alt"></i> Repeat Yearly
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RepeatField;