import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import Auth from "../libs/Auth";
import ErrorNotice from "./ErrorNotice";
import {startLoading, stopLoading} from "../../admin";
import {useDispatch} from "react-redux";

function ResetPassword({location, history}) {
    document.body.classList.add('bg-gradient-primary');

    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');
    const [hash, setHash] = useState('');

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        setEmail(pathParts[2]);
        setHash(pathParts[3]);
    }, [location.pathname]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(startLoading());

        if (password === passwordConfirm) {
            Auth.reset(email, password, passwordConfirm, hash)
                .then(() => {
                    alert('Your password has been changed successfully');
                    history.push('/');
                })
                .catch((errors) => setErrors(Object.values(errors)))
                .finally(() => dispatch(stopLoading()))
            ;
        } else {
            setErrors(["Passwords don't match"]);
        }
    };

    return (
        <Container>
            {errors && <ErrorNotice errors={errors} />}
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                        <div className="col-lg-6">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Reset your password?</h1>
                                    <p className="mb-4">Here you can reset your password. Just enter your new password
                                        and confirm it and your password will be reset!</p>
                                </div>
                                <form className="user" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input type="password" className="form-control form-control-user"
                                               onChange={(e) => setPassword(e.target.value)}
                                               placeholder="Enter Password..."
                                               required />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control form-control-user"
                                               onChange={(e) => setPasswordConfirm(e.target.value)}
                                               placeholder="Repeat Password..."
                                               required />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                        Reset Password
                                    </button>
                                </form>
                                <hr/>
                                <div className="text-center">
                                    <Link className="small" to="/register">Create an Account!</Link>
                                </div>
                                <div className="text-center">
                                    <Link className="small" to="/">Already have an account? Login!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ResetPassword;