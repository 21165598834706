import React from "react";
import {Button, ToggleButtonGroup} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import views from "../constants/views";
import {toggleView} from "../actions/view";

function ViewToggle() {
    const dispatch = useDispatch();
    const view = useSelector((state) => state.view);

    return (
        <div>
            <ToggleButtonGroup name="view">
                <Button active={view === views.future} onClick={() => dispatch(toggleView(views.future))}>Scheduled</Button>
                <Button active={view === views.today} onClick={() => dispatch(toggleView(views.today))}>Today</Button>
                <Button active={view === views.closed} onClick={() => dispatch(toggleView(views.closed))}>Done</Button>
            </ToggleButtonGroup>
        </div>
    )
}

export default ViewToggle;