import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import Auth from "../libs/Auth";
import ErrorNotice from "./ErrorNotice";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errors, setErrors] = useState([]);

    document.body.classList.add('bg-gradient-primary');

    const login = (e) => {
        e.preventDefault();

        Auth.login(email, password)
            .then(() => window.location.reload())
            .catch(() => setErrors(['Login unsuccessful']));
    };

    const changeEmail = (event) => {
        setEmail(event.target.value);
    };

    const changePassword = (event) => {
        setPassword(event.target.value);
    };

    return (
        <Container>
            {errors && <ErrorNotice errors={errors} />}
            <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                        <div className="col-lg-6">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                </div>
                                <form className="user" onSubmit={login}>
                                    <div className="form-group">
                                        <input type="email" className="form-control form-control-user"
                                               aria-describedby="emailHelp"
                                               placeholder="Enter Email Address..."
                                               onChange={changeEmail} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control form-control-user"
                                               placeholder="Password" autoComplete="off"
                                               onChange={changePassword} required />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-user btn-block">
                                        Login
                                    </button>
                                </form>
                                <hr/>
                                <div className="text-center">
                                    <Link className="small" to="/forgot-password">Forgot Password?</Link>
                                </div>
                                <div className="text-center">
                                    <Link className="small" to="/register">Create an Account!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Login;