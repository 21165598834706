import {dateSelectActions} from "../actions/dateSelection";
import dateSelectionViews from "../constants/dateSelectionViews";

const dateSelection = (state = {view: dateSelectionViews.hidden}, action) => {
    switch (action.type) {
        case dateSelectActions.DATE_SELECT_HIDE:
            return Object.assign({}, state, {view: dateSelectionViews.hidden});
        case dateSelectActions.DATE_SELECT_CUSTOM:
            return Object.assign({}, state, {view: dateSelectionViews.custom});
        case dateSelectActions.DATE_SELECT_OPTIONS:
            return Object.assign({}, state, {
                view: dateSelectionViews.options,
                saveDirectly: action.saveDirectly || false
            });
        default:
            return state;
    }
};

export default dateSelection;