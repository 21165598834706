import React from "react";
import {useSelector} from "react-redux";
import {SwipeableList} from "@sandstreamdev/react-swipeable-list";

function TaskListProvider({children}) {
    const searchString = useSelector(store => store.search.search);

    const tasks = useSelector((state) => searchString === ''
        ? state.tasks
        : state.tasks.filter(task => task.subject.toLowerCase().search(searchString.toLowerCase()) !== -1)
    );

    const childrenWithProps = React.Children.map(
        children,
        child => React.cloneElement(child, {tasks})
    );

    return (
        <>
            <SwipeableList>
                {childrenWithProps}
            </SwipeableList>
        </>
    );
}

export default TaskListProvider;