import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Button, Form, Row, Col, Collapse} from "react-bootstrap";
import {newTaskClose, setSubject, setDescription} from "../actions/forms";
import {createTask} from "../actions/tasks";
import DateField from "./DateField";
import RepeatField from "./RepeatField";
import SubTaskField from "./SubTaskField";
import FileField from "./FileField";

function NewTaskModal() {
    const show = useSelector((state) => state.task.forms.newTaskOpen);
    const task = useSelector((state) => state.task.forms);
    const subjectField = React.createRef();
    const [focused, setFocused] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);

    const dispatch = useDispatch();

    const close = () => {
        dispatch(newTaskClose());
        setShowAdvanced(false);
        setTimeout(() => setFocused(false), 500);
    };

    useEffect(() => {
        if (subjectField.current !== null && focused === false) {
            subjectField.current.focus();
            setFocused(true);
        }

    }, [subjectField, focused]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createTask({
            subject: task.subject,
            description: task.description,
            date: task.date,
            task_repeat: task.task_repeat,
            sub_tasks: task.sub_tasks,
            files: task.files
        }));
        close();
    };

    return (
        <Modal show={show} onHide={close} size="xl">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Task</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="subject">
                        <Form.Control required type="text" placeholder="The subject of your task"
                                      value={task.subject || ''}
                                      ref={subjectField}
                                      onChange={(e) => dispatch(setSubject(e.target.value))}/>
                    </Form.Group>
                    <Row>
                        <Col>
                            {showAdvanced === true
                                ? <Button variant="link" onClick={() => setShowAdvanced(false)}>Hide advanced</Button>
                                : <Button variant="link" onClick={() => setShowAdvanced(true)}>Show advanced</Button>
                            }
                        </Col>
                    </Row>
                    <Collapse in={showAdvanced}>
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <SubTaskField />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <FileField />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Group controlId="description">
                                        <Form.Control as="textarea" placeholder="Description"
                                                      value={task.description || ''}
                                                      onChange={(e) => dispatch(setDescription(e.target.value))}/>
                                    </Form.Group>
                                    <DateField />
                                    <RepeatField />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={close} variant="secondary">Close</Button>
                    <Button variant="primary" type="submit">Create</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default NewTaskModal;
