import Api from "../../libs/Api";

const clientId = process.env.REACT_APP_CLIENT_ID || 1;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || 'yblzDGyPdVlY0k0VWLPRivANOkNttX6kL4GDZtzX';

class Auth {
    register = (fullName, email, password, passwordConfirmation) => {
        let userData = {
            name: fullName,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
        };
        return new Promise((resolve, reject) => {
            Api.post('/api/register', userData).then(response => {
                if (((response.data || {}).id || 0) > 0) {
                    resolve();
                }

                console.log(response);
                reject(response.data.errors || []);
            }).catch((error) => reject((((error || {}).response || {}).data || {}).errors || []));
        })
    };

    confirm = (id, hash) => {
        let payload = {
            id: id,
            hash: hash
        };

        return new Promise((resolve, reject) => {
            Api.post('/api/register/verify', payload).then(response => {
                if ((response.data || {}).status || '' === "success") {
                    resolve();
                }
                reject(response.data.status || null);
            }).catch(() => reject(null));
        });
    };

    forgotPassword = (email) => {
        return new Promise((resolve, reject) => {
            Api.post(
                '/api/forgot-password',
                {
                    email: email
                }
            ).then(response => {
                if ((response.data || {}).status || '' === "success") {
                    resolve();
                }
                reject(response.data.status || null);
            }).catch(() => reject(null));
        });
    };

    reset = (email, password, passwordConfirmation, token) => {
        return new Promise((resolve, reject) => {
            Api.post(
                '/api/forgot-password/reset',
                {
                    email: email,
                    password: password,
                    password_confirmation: passwordConfirmation,
                    token: token
                }
            ).then(response => {
                if ((response.data || {}).status || '' === "success") {
                    resolve();
                }

                reject(response.data.errors || []);
            }).catch((error) => reject((((error || {}).response || {}).data || {}).errors || []));
        });
    };

    login = (email, password) => {
        return new Promise((resolve, reject) => {
            Api.post(
                '/oauth/token',
                {
                    grant_type: "password",
                    client_id: clientId,
                    client_secret: clientSecret,
                    username: email,
                    password: password,
                    scope: "*"
                },
            ).then(response => {
                if (response.data.access_token !== undefined && response.data.refresh_token !== undefined) {
                    this.setRefreshToken(response.data.refresh_token);
                    this.setToken(response.data.access_token);

                    resolve();
                }
                reject(response.data.errors || []);
            }).catch((error) => reject((((error || {}).response || {}).data || {}).errors || []));
        });
    };

    logout = () => {
        this.clearData();
    };

    getUserData = () => {
        return new Promise((resolve, reject) => {
            Api.get(
                '/api/user',
                {},
            ).then(response => {
                if (response.data.id !== undefined) {
                    resolve(response.data);
                }

                reject(response.data || null);
            }).catch(() => reject(null));
        });
    };

    getToken = () => localStorage.getItem('token');

    setToken = (token) => localStorage.setItem("token", token);

    getRefreshToken = () => localStorage.getItem("refreshToken");

    setRefreshToken = (refreshToken) => localStorage.setItem("refreshToken", refreshToken);

    isLoggedIn = () => localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined;

    clearData = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
    };
}

export default new Auth();
