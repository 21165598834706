import Api from "../../libs/Api";
import DateHelper from "./DateHelper";
import dateFormat from "dateformat"

class TaskHelper {

    getVars = () => ({'currentDateTime': dateFormat(DateHelper.getNow(), DateHelper.getDateFormatString())});

    getToday = () => {
        return new Promise((resolve, reject) => {
            Api.get('/api/tasks/today/open', this.getVars()).then((response) => {
                if (Array.isArray(response.data)) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });

    };

    getClosed = () => {
        return new Promise((resolve, reject) => {
            Api.get('/api/tasks/today/closed', this.getVars()).then((response) => {
                if (Array.isArray(response.data)) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    getFuture = () => {
        return new Promise((resolve, reject) => {
            Api.get('/api/tasks/future/open', this.getVars()).then((response) => {
                if (Array.isArray(response.data)) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    changePositions = (newPositions) => {
        return new Promise((resolve, reject) => {
            Api.post('/api/tasks/today/positions-change', {positions: newPositions}).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    create = (subject, description, taskRepeat = null, subTasks = [], files = [], date = null) => {
        const task = {
            subject, description, date, task_repeat: taskRepeat, sub_tasks: subTasks, files
        };

        return new Promise((resolve, reject) => {
            Api.post('/api/tasks', task).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    update = (id, subject, description, status, taskRepeat, subTasks = [], files = [], date = null) => {
        const task = {
            subject, description, status, date, task_repeat: taskRepeat, sub_tasks: subTasks, files
        };

        return new Promise((resolve, reject) => {
            Api.put('/api/tasks/' + id, task).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    delete = (id) => {
        return new Promise((resolve, reject) => {
            Api.delete('/api/tasks/' + id).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    close = (id) => {
        return new Promise((resolve, reject) => {
            Api.post('/api/tasks/close', {task_id: id}).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    };

    open = (id) => {
        return new Promise((resolve, reject) => {
            Api.post('/api/tasks/open', {task_id: id}).then((response) => {
                if (response.data !== undefined) {
                    resolve(response.data);
                }
                reject(response);
            }).catch((response) => reject(response));
        });
    }
}

export default new TaskHelper();
