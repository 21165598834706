import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faCheckSquare, faAngleUp, faAngleRight, faCog } from '@fortawesome/free-solid-svg-icons'

import './assets/scss/sb-admin-2.scss'
import './assets/js/sb-admin-2'

import 'bootstrap/dist/js/bootstrap.min.js'
import Auth from "./auth/libs/Auth";
import {loggedInRoutes, guestRoutes} from "./routes";

function App() {
    library.add(faPlusCircle, faCheckSquare, faAngleUp, faAngleRight, faCog);

    return (
        <Router>
            <Switch>
                {Auth.isLoggedIn() &&
                    loggedInRoutes.map((route, index) => <Route key={route.name} path={route.path}
                                                            exact={route.exact} render={route.component}/>)
                }
                { ! Auth.isLoggedIn() &&
                    guestRoutes.map((route, index) => <Route key={route.name} path={route.path} render={route.component} exact={route.exact}/>)
                }
            </Switch>
        </Router>
    );
}

export default App;
