import {Modal} from "react-bootstrap";
import {hideDateSelection, showDateOptions} from "../actions/dateSelection";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import DateHelper from "../libs/DateHelper";
import {setDate} from "../actions/forms";
import dateSelectionViews from "../constants/dateSelectionViews";
import {updateTask} from "../actions/tasks";

function CustomDateModal() {
    const dispatch = useDispatch();

    const saveDirectly = useSelector(store => store.task.dateSelection.saveDirectly);
    const date = useSelector(store => store.task.forms.date);
    const task = useSelector((state) => state.task.forms);
    const show = useSelector(store => store.task.dateSelection.view === dateSelectionViews.custom);

    const onDateChange = date => {
        let newDate;

        if (date === null) {
            newDate = null
        } else {
            date.setHours(DateHelper.getStartHour());
            date.setMinutes(0);
            newDate = dateFormat(date, DateHelper.getDateFormatString());
        }

        dispatch(setDate(newDate));
        if (saveDirectly) {
            dispatch(updateTask(Object.assign({}, task, {date: newDate, status: 'open'})));
        }
        dispatch(hideDateSelection());
    };

    const getDateObject = (date) => {
        if (date === undefined || date === null)
            return null;

        return new Date(date);
    };

    return (
        <Modal show={show} onHide={() => dispatch(showDateOptions())} size="sm">
            <Modal.Body>
                <DayPicker
                    selectedDays={[getDateObject(date)]}
                    onDayClick={(date) => {
                        onDateChange(date);
                        dispatch(hideDateSelection());
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default CustomDateModal;