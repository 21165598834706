class DateHelper {

    // settings (later determine through profile settings)
    getStartHour = () => 8;
    getEveningHour = () => 18;
    getLaterHours = () => 2;
    getWeekStart = () => 1;
    getWeekendDay = () => 6;

    getDateFormatString = () => 'yyyy-mm-dd HH:MM';

    isMorning = () => new Date(Date.now()).getHours() < 18;

    getNow = () => new Date(Date.now());

    getEvening = () => {
        let evening  = new Date(Date.now());
        evening.setHours(this.getEveningHour());
        evening.setMinutes(0);
        return evening;
    };

    getLaterToday = () => {
        let laterToday = new Date(Date.now() + this.getLaterHours() * 3600000);
        laterToday.setMinutes(0);
        return laterToday;
    };

    getTomorrow = () => {
        let tomorrow = new Date(Date.now() + 86400000);
        tomorrow.setHours(this.getStartHour());
        tomorrow.setMinutes(0);
        return tomorrow;
    };

    getTomorrowEvening = () => {
        let tomorrowEvening = new Date(Date.now() + 86400000);
        tomorrowEvening.setHours(this.getEveningHour());
        tomorrowEvening.setMinutes(0);
        return tomorrowEvening;
    };

    getAfterTomorrow = () => {
        let afterTomorrow = new Date(Date.now() + 2 * 86400000);
        afterTomorrow.setHours(this.getStartHour());
        afterTomorrow.setMinutes(0);
        return afterTomorrow;
    };

    getComingWeekend = () => {
        let comingWeekend = new Date(Date.now());
        comingWeekend.setHours(this.getStartHour());
        comingWeekend.setMinutes(0);

        do {
            comingWeekend = new Date(comingWeekend.getTime() + 86400000);
        }
        while(comingWeekend.getDay() !== this.getWeekendDay());

        return comingWeekend;
    };

    getNextWeek = () => {
        let nextWeek = new Date(Date.now());
        nextWeek.setHours(this.getStartHour());
        nextWeek.setMinutes(0);

        do {
            nextWeek = new Date(nextWeek.getTime() + 86400000);
        }
        while(nextWeek.getDay() !== this.getWeekStart());

        return nextWeek;
    }
}

export default new DateHelper();