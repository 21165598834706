import React, {useState, useEffect} from "react";
import {Container, Alert} from "react-bootstrap";
import Auth from "../libs/Auth";

import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../admin";

function Activate({location, history}) {
    document.body.classList.add('bg-gradient-primary');

    const dispatch = useDispatch();
    const [activated, setActivated] = useState(false);

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const hash = pathParts[2];
        const id = pathParts[3];

        dispatch(startLoading());

        Auth.confirm(id, hash)
            .then(() => setActivated(true))
            .catch(() => setActivated(false))
            .finally(() => dispatch(stopLoading()));

        if (activated) {
            setTimeout(() => history.push('/'), 5000);
        }
    });

    const ActivatedNotice = () => (
        <Alert variant="success" className="mt-5">
            Activation successful, you can log in
        </Alert>
    );

    const NotActivatedNotice = () => (
        <Alert variant="danger" className="mt-5">
            Activation failed or account already activated
        </Alert>
    );

    return (
        <Container>
            {activated ? <ActivatedNotice /> : <NotActivatedNotice /> }
        </Container>
    );
}

export default Activate;