import React from "react";
import '../style/index.scss'
import TaskItem from "./TaskItem";
import {useSelector} from "react-redux";
import {groupBy, sortBy} from "lodash"
import moment from "moment";
import views from "../constants/views";

function FutureTaskList({tasks}) {
    const searchString = useSelector(store => store.search.search);

    const byDate = sortBy(
        Object.entries(groupBy(tasks, task => task.repeat_date || task.date)),
        ([date,]) => date || '9999-12-31'
    );

    const formatDate = (date) => {
        return date === 'null' ? 'Unspecified' : moment(date).calendar(null, {sameElse: 'YYYY-MM-DD'})
    };

    return (
        <ul className="task-list align-middle">
            {Object.keys(byDate).length === 0 &&
            (
                searchString === ''
                    ? <span>No plans? Add some tasks for the future!</span>
                    : <span><i className="fa fa-search"></i> Nothing found for "{searchString}"</span>
            )}
            {byDate.map(([date, taskItems]) => {
                const title = formatDate(date);
                return (
                    <div key={`cat-${date}`} className="task-date-cat">
                        <h2>{title}</h2>
                        {taskItems.map((task) => <TaskItem view={views.future} key={`item-${task.id}`} task={task}/>)}
                    </div>
                )
            })}
        </ul>
    );
}

export default FutureTaskList;