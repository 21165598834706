import {Button} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {closeTask, openTask, updateTask} from "../actions/tasks";
import {sortableElement, sortableHandle} from "react-sortable-hoc";
import {SwipeableListItem} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import {editTaskOpen, setCurrentTask} from "../actions/forms";
import views from "../constants/views";
import {showDateOptions} from "../actions/dateSelection";
import DateHelper from "../libs/DateHelper";
import dateFormat from "dateformat";

function TaskItem({index, view, task, isDraggable}) {
    const dispatch = useDispatch();

    const [justSwiped, setJustSwiped] = useState(false);

    const close = (task) => dispatch(closeTask(task));

    const open = (task) => dispatch(openTask(task));

    const plan = (task) => {
        dispatch(setCurrentTask(task));
        dispatch(showDateOptions(true));
    };

    const setNow = (task) => {
        dispatch(updateTask(Object.assign({}, task, {date: dateFormat(new Date(), DateHelper.getDateFormatString())})));
    };

    const openEditForm = (task) => {
        if (!justSwiped)
            dispatch(editTaskOpen(task))
    };

    const DragHandle = sortableHandle(() => <div className="task-handle">
        <div className="icon"></div>
    </div>);

    const Indicators = () => (
        <span className="indicators">
            {task.files.length > 0 ? <i className="fa fa-xs fa-paperclip"></i> : <span className="no-file"></span>}
            {task.task_repeat ? <i className="fa fa-xs fa-redo"></i> : <span className="no-repeat"></span>}
            {task.sub_tasks.length ? <i className="fa fa-xs fa-clipboard-list"></i> : <span className="no-subtasks"></span>}
            {(task.description || "").length > 0 ? <i className="fa fa-xs fa-comment-alt"></i> : <span className="no-description"></span>}
        </span>
    );

    let swipeOptions = {};

    switch (view) {
        case views.today:
            swipeOptions['swipeRight'] = {
                content: <div className="task-swipe-action task-swipe-close">Done</div>,
                action: () => {
                    setJustSwiped(true);
                    close(task);
                    setTimeout(() => setJustSwiped(false), 300)
                }
            };
            swipeOptions['swipeLeft'] = {
                content: <div className="task-swipe-action task-swipe-plan">Schedule</div>,
                action: () => {
                    setJustSwiped(true);
                    plan(task);
                    setTimeout(() => setJustSwiped(false), 300)
                }
            };
            break;
        case views.closed:
            swipeOptions['swipeLeft'] = {
                content: <div className="task-swipe-action task-swipe-reopen">Reopen</div>,
                action: () => {
                    setJustSwiped(true);
                    open(task);
                    setTimeout(() => setJustSwiped(false), 300)
                }
            };
            break;
        default:
            swipeOptions['swipeRight'] = {
                content: <div className="task-swipe-action task-swipe-now">Plan for today</div>,
                action: () => {
                    setJustSwiped(true);
                    setNow(task);
                    setTimeout(() => setJustSwiped(false), 300)
                }
            };
            swipeOptions['swipeLeft'] = {
                content: <div className="task-swipe-action task-swipe-plan">Reschedule</div>,
                action: () => {
                    setJustSwiped(true);
                    plan(task);
                    setTimeout(() => setJustSwiped(false), 300)
                }
            };
    }

    let Buttons;

    switch (view) {
        case views.today:
            Buttons = () => (
                <>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        plan(task)
                    }}>Schedule</Button>{' '}
                    <Button variant="success" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        close(task)
                    }}>Done</Button>
                </>
            );
            break;
        case views.closed:
            Buttons = () => <Button onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                open(task)
            }}>Reopen</Button>;
            break;
        default:
            Buttons = () => (
                <>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNow(task)
                    }}>Plan for today</Button>{' '}
                    <Button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        plan(task)
                    }}>Reschedule</Button>
                </>
            );
    }

    const Item = ({task}) => (
        <SwipeableListItem
            {...swipeOptions}
        >
            <li className={isDraggable || false ? "task-item draggable" : "task-item"}
                onClick={() => openEditForm(task)}>
                {isDraggable && <DragHandle/>}
                <Indicators />
                <span className="text">{task.subject}</span>
                <div className="task-buttons">
                    <Buttons/>
                </div>

            </li>
        </SwipeableListItem>
    );

    const SortableElement = sortableElement(Item);

    return isDraggable ? <SortableElement index={index} task={task}/> : <Item task={task}/>;
}

export default TaskItem;