import React, {useEffect, useState} from "react";
import '../style/date_field.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    addUnsavedSubTask,
    closeUnsavedSubTask,
    editSubTaskSubject,
    openUnsavedSubTask,
    deleteUnsavedSubTask
} from "../actions/forms";
import {Form, InputGroup} from "react-bootstrap";

const SubTaskField = () => {
    const dispatch = useDispatch();
    const subTasks = useSelector(store => store.task.forms.sub_tasks || []);
    const [subTaskElements, setSubTaskElements] = useState({});
    const [shouldFocus, setShouldFocus] = useState('');

    useEffect(() => {
        if (shouldFocus !== '') {
            const field = subTaskElements[shouldFocus] || null;
            if (field !== null) {
                setShouldFocus('')
                field.focus()
            }
        }
    }, [subTaskElements, shouldFocus])

    const switchState = subTask => {
        if (subTask.status === 'open') {
            dispatch(closeUnsavedSubTask(subTask))
        } else {
            dispatch(openUnsavedSubTask(subTask))
        }
    }

    const remove = subTask => {
        dispatch(deleteUnsavedSubTask(subTask))
    }

    const changeSubTaskSubject = (e, subTask) => {
        dispatch(editSubTaskSubject(subTask, e.target.value));
    }

    const getReference = (i, element) => {
        if (subTaskElements[i] === element || element === null) {
            return
        }

        let els = Object.fromEntries(Object.entries(subTaskElements).slice().filter(([i, el]) => el !== null))
        els[i] = element;
        setSubTaskElements(els);
    }

    const add = () => {
        const tempHash = Math.random().toString(36).substring(7)
        dispatch(addUnsavedSubTask(tempHash))
        setShouldFocus(tempHash);
    }

    return (
        <div>
            <div className="sub-tasks">
                {subTasks.map((subTask) => (
                    <div key={subTask.hash} className="sub-task">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <button onClick={e => {
                                        e.preventDefault();
                                        switchState(subTask)
                                    }} className="btn btn-default"><i
                                        className={subTask.status === 'open' ? "far fa-square" : 'fa fa-check-square'}></i>
                                    </button>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control required type="text"
                                          value={subTask.subject || ''}
                                          ref={element => getReference(subTask.hash, element)}
                                          onChange={e => changeSubTaskSubject(e, subTask)}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <button tabIndex="-1" onClick={e => {
                                        e.preventDefault();
                                        remove(subTask)
                                    }} className="btn btn-default"><i className="fa fa-trash"></i></button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                ))}
            </div>
            <button onClick={() => add()} type="button" className="btn btn-sm btn-secondary">
                <i className="fa fa-plus"></i> Add sub task
            </button>
        </div>
    )
}

export default SubTaskField;