import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {newTaskOpen} from "..";

function NewTaskButton() {
    const editFormOpen = useSelector((state) => state.task.forms.editTaskOpen);
    const newFormOpen = useSelector((state) => state.task.forms.newTaskOpen);
    const dispatch = useDispatch();

    const open = () => {
        dispatch(newTaskOpen())
    };

    return (
        <>
            {!editFormOpen && !newFormOpen &&
            <button onClick={open} id="add-button-mobile" className="btn btn-primary d-md-none rounded-circle mr-3">
                <i className="fa fa-plus"></i>
            </button>
            }
        </>
    );
}

export default NewTaskButton;