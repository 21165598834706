import React from "react";
import '../style/index.scss'
import TaskItem from "./TaskItem";
import {useDispatch, useSelector} from "react-redux";
import {sortableContainer} from 'react-sortable-hoc';
import {moveTasks} from "../actions/tasks";
import views from "../constants/views";

function OrderableTaskList({tasks}) {

    const dispatch = useDispatch();
    const searchString = useSelector(store => store.search.search);

    const onSortEnd = ({oldIndex, newIndex}) => {
        dispatch(moveTasks(oldIndex, newIndex));
    };

    const SortableContainer = sortableContainer(({children}) => {
        return <ul className="task-list align-middle">{children}</ul>;
    });

    return (
        <SortableContainer onSortEnd={onSortEnd} helperClass="dragging" useDragHandle >
            {Object.keys(tasks).length === 0 &&
            (
                searchString === ''
                ? <span><i className="fa fa-check"></i> Fabulous! All tasks are done!</span>
                : <span><i className="fa fa-search"></i> Nothing found for "{searchString}"</span>
            )}
            {tasks.map((task, index) => {
                return (
                    <TaskItem key={`item-${task.id}`} view={views.today} index={index} task={task} isDraggable />
                )
            })}
        </SortableContainer>
    );
}

export default OrderableTaskList;