import {taskFormActions} from "../actions/forms";
import dateFormat from "dateformat";
import DateHelper from "../libs/DateHelper";

const initialState = {
    newTaskOpen: false,
    editTaskOpen: false,
    task: {},
    sub_tasks: [],
    files: [],
    task_repeat: {period: null}
};

const forms = (state = initialState, action) => {
    switch (action.type) {
        case taskFormActions.TASK_SET_CURRENT:
            return Object.assign(
                {},
                state,
                {
                    ...action.task,
                    sub_tasks: action.task.sub_tasks.map(subTask => {
                        if (subTask.id) {
                            subTask.hash = subTask.id;
                        }
                        return subTask
                    })
                }
            );
        case taskFormActions.TASK_NEW_OPEN:
            return Object.assign({}, state, {
                newTaskOpen: true,
                date: dateFormat(new Date(), DateHelper.getDateFormatString()),
                description: '',
                subject: '',
                task_repeat: {period: null},
                files: [],
                sub_tasks: [],
            });
        case taskFormActions.TASK_NEW_CLOSE:
            return Object.assign({}, state, {newTaskOpen: false});
        case taskFormActions.TASK_EDIT_OPEN:
            return Object.assign(
                {},
                state,
                {
                    editTaskOpen: true,
                    ...action.task,
                    sub_tasks: action.task.sub_tasks.map(subTask => {
                        if (subTask.id) {
                            subTask.hash = subTask.id;
                        }
                        return subTask
                    })
                }
            );
        case taskFormActions.TASK_EDIT_CLOSE:
            return Object.assign({}, state, {editTaskOpen: false});
        case taskFormActions.TASK_EDIT_FORM_SET_SUBJECT: {
            return Object.assign({}, state, {subject: action.subject});
        }
        case taskFormActions.TASK_EDIT_FORM_SET_DESCRIPTION: {
            return Object.assign({}, state, {description: action.description});
        }

        case taskFormActions.TASK_EDIT_FORM_SET_DATE: {
            return Object.assign({}, state, {date: action.date});
        }

        case taskFormActions.TASK_REPEAT_SET: {
            return Object.assign({}, state, {task_repeat: {period: action.period}});
        }

        case taskFormActions.SUB_TASKS_SET: {
            return Object.assign(
                {},
                state,
                {
                    ...action.task,
                    sub_tasks: action.task.sub_tasks.map(subTask => {
                        if (subTask.id) {
                            subTask.hash = subTask.id;
                        }
                        return subTask
                    })
                }
            );
        }

        case taskFormActions.SUB_TASK_ADD: {
            return Object.assign({}, state, {
                sub_tasks: state.sub_tasks.concat([{
                    subject: '',
                    status: 'open',
                    closed_at: null,
                    hash: action.hash
                }])
            });
        }
        case taskFormActions.SUB_TASK_SET_SUBJECT: {
            let subTasks = state.sub_tasks.slice()
            const hash = action.subTask.hash
            subTasks.filter(subTask => subTask.hash === hash)[0].subject = action.subject
            return Object.assign({}, state, {sub_tasks: subTasks});
        }
        case taskFormActions.SUB_TASK_OPEN: {
            let subTasks = state.sub_tasks.slice()
            subTasks.filter(subTask => subTask.hash === action.subTask.hash)[0].status = 'open'
            return Object.assign({}, state, {sub_tasks: subTasks});
        }
        case taskFormActions.SUB_TASK_CLOSE: {
            let subTasks = state.sub_tasks.slice()
            subTasks.filter(subTask => subTask.hash === action.subTask.hash)[0].status = 'closed'
            return Object.assign({}, state, {sub_tasks: subTasks});
        }
        case taskFormActions.SUB_TASK_DELETE: {
            let subTasks = state.sub_tasks.slice()
            subTasks = subTasks.filter(subTask => subTask.hash !== action.subTask.hash)
            return Object.assign({}, state, {sub_tasks: subTasks});
        }

        case taskFormActions.FILE_ADD: {
            return Object.assign({}, state, {files: state.files.concat([action.file])})
        }

        case taskFormActions.FILE_DELETE: {
            let files = state.files.slice()
            files = files.filter(file => file.id !== action.fileId)
            return Object.assign({}, state, {files});
        }

        default:
            return state;
    }
};

export default forms;