const dateSelectActions = {
    DATE_SELECT_HIDE: 'DATE_SELECT_HIDE',
    DATE_SELECT_OPTIONS: 'DATE_SELECT_OPTIONS',
    DATE_SELECT_CUSTOM: 'DATE_SELECT_CUSTOM'
};

const hideDateSelection = () => {
    return {
        type: dateSelectActions.DATE_SELECT_HIDE
    };
};

const showDateOptions = (saveDirectly = false) => {
    return {
        type: dateSelectActions.DATE_SELECT_OPTIONS,
        saveDirectly
    };
};

const showDateCustom = () => {
    return {
        type: dateSelectActions.DATE_SELECT_CUSTOM
    };
};

export {dateSelectActions, hideDateSelection, showDateCustom, showDateOptions};