import React from "react";
import {useSelector} from "react-redux";

function Loader() {
    const isLoading = useSelector((state) => state.loading.loading);

    return (
        <>
            {isLoading === true ? (
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                        <div className="loader-bg"></div>
                    </div>
                )
                : <div></div>
            }
        </>
    )
        ;
}

export default Loader;