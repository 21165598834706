const SEARCH = 'SEARCH';
const FOCUS = 'FOCUS';
const BLUR = 'BLUR';

const search = search => ({
    type: SEARCH,
    search: search
});

const searchFocus = () => ({
    type: FOCUS
});

const searchBlur = () => ({
    type: BLUR
});

export {search, searchBlur, searchFocus, SEARCH, FOCUS, BLUR};