import Sidebar from "../layout/components/Sidebar";
import Navigation from "../layout/components/Navigation";
import Footer from "../layout/components/Footer";
import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {newTaskOpen} from "../../tasks";
import Loader from "../layout/components/Loader";
import "../style/index.scss";

function Admin({children}) {
    document.body.classList.add("sidebar-toggled");
    document.body.id = 'page-top';

    const dispatch = useDispatch();
    const newTaskOpened = useSelector((state) => state.task.forms.newTaskOpen);
    const editTaskOpened = useSelector((state) => state.task.forms.editTaskOpen);
    const isSearching = useSelector((state) => state.search.isFocused);

    const handleKeyPress = (e) => {
        if (e.key === ' ' && ! newTaskOpened && ! editTaskOpened && ! isSearching) {
            e.preventDefault();
            dispatch(newTaskOpen());
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);

        return () => document.removeEventListener('keypress', handleKeyPress);
    });

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navigation/>
                    <Container fluid>
                        <Loader />
                        {children}
                    </Container>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Admin;