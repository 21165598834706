import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {newTaskOpen} from "../../../tasks";
import {useDispatch} from "react-redux";

function Sidebar() {
    const dispatch = useDispatch();

    return  (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
            id="accordionSidebar">

            <button className="sidebar-brand d-flex align-items-center justify-content-center btn" onClick={() => dispatch(newTaskOpen())}>
                <div className="sidebar-brand-icon">
                    <FontAwesomeIcon icon='plus-circle' />
                </div>
                <div className="sidebar-brand-text mx-3">Swipe tasks</div>
            </button>

            <hr className="sidebar-divider my-0"/>

            <li className="nav-item active">
                <Link className="nav-link" to="/">
                    <FontAwesomeIcon icon="check-square" />{' '}
                    <span>Tasks</span>
                </Link>
            </li>

            {/*
            <hr className="sidebar-divider"/>

            <div className="sidebar-heading">
                Interface
            </div>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="#" data-toggle="collapse" data-target="#collapseTwo"
                   aria-expanded="true" aria-controls="collapseTwo">
                    <FontAwesomeIcon icon="cog" />{' '}
                    <span>Settings</span>
                </Link>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                     data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Settings:</h6>
                        <Link className="collapse-item" to="/">Dummy Setting</Link>
                        <Link className="collapse-item" to="/">Dummy Setting 2</Link>
                    </div>
                </div>
            </li>
            */}

            <hr className="sidebar-divider d-none d-md-block"/>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" />
            </div>

        </ul>
    )
}

export default Sidebar;