import axios from "axios";
import uuid from "uuid";
import Auth from "./../auth/libs/Auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost';

class Api {
    sessionId = '';

    constructor() {
        this.createSessionId();
    }

    get = (url, initalGetVars={}) => {
        let {getVars, config} = this.defaults(initalGetVars);
        return axios.get(this.addVarsToUrl(url, getVars), config).then(this.handleAuthError);
    };

    post = (url, data={}, initalGetVars={}) => {
        let {getVars, config} = this.defaults(initalGetVars);
        return axios.post(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
    };

    put = (url, data={}, initalGetVars={}) => {
        let {getVars, config} = this.defaults(initalGetVars);
        return axios.put(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
    };

    delete = (url, initalGetVars={}) => {
        let {getVars, config} = this.defaults(initalGetVars);
        return axios.delete(this.addVarsToUrl(url, getVars), config).then(this.handleAuthError);
    };

    defaults = (initalGetVars) => {
        let getVars = initalGetVars;
        let config = {};
        if (Auth.isLoggedIn()) {
            config = {headers: {'Authorization': 'Bearer ' + Auth.getToken()}};
        }

        return {getVars, config};
    };

    handleAuthError = (response) => {
        let code = response.data[0] || false;
        if (code === 401) {
            // alert(i18next.t('Your login has expired. Please login again'));
            Auth.clearData();
            window.location.reload();
            throw new Error("ForgotPassword expired");
        }
        return response;
    };

    addVarsToUrl = (url, getVars) => url + '?' + Object.keys(getVars).map((key) => key + '=' + encodeURIComponent(getVars[key])).join('&');

    createSessionId = () => {
        let sessionId = localStorage.getItem('sessionId') || '';
        if (sessionId === '') {
            sessionId = uuid.v4();
            localStorage.setItem('sessionId', sessionId);
        }

        this.sessionId = sessionId;
    };
}

export default new Api();
